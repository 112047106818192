import React from "react";
import SectionTitle from "../sectionTitle/SectionTitle";
import FeatureTwo from "../feature/FeatureTwo";
import { Link } from "react-scroll";
import { Box } from "@mui/material";

const OurPrograms = () => {
  return (
    <div
      className="eduvibe-home-four-service edu-service-area bg-color-white position-relative"
      style={{ padding: "50px 0px" }}
    >
      <div className="container eduvibe-animated-shape">
        <div className="row">
          <div className="col-lg-12">
            <SectionTitle
              classes="text-center"
              slogan="What We Offer"
              title="Our Programs"
            />
          </div>
        </div>

        <FeatureTwo bgColor="bg-grey" />

        {/* <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-29.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-03-06.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-02-06.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-4">
                        <img src="/images/shapes/shape-19-02.png" alt="Shape Thumb" />
                    </div>
                </div> */}
<Box textAlign={"center"} pt={3}>
<Link
                    // className="edu-btn"
                    to="contact-us"
                    spy={true}
                    smooth={true}
                    duration={200}
                  >
                    <button class="btnn">
                      <i class="animation"></i>Claim your discount<i class="animation"></i>
                    </button>
                  </Link>
</Box>

      </div>
    </div>
  );
};

export default OurPrograms;
