import { Grid } from "@mui/material";
import React from "react";
import Marquee from "react-fast-marquee";
import SectionTitle from "../../components/sectionTitle/SectionTitle";

const images = [
  "/images/brand-logos/loreal.webp",
  "/images/brand-logos/mashreq.webp",
  "/images/brand-logos/rakbank.webp",
  "/images/brand-logos/nbd.webp",
  "/images/brand-logos/sc.webp",
//   "/images/brand-logos/almarai.webp",
  "/images/brand-logos/emirates.webp",
];

function Accreditation() {
  return (
    <>
      <Grid py={"50px"}>
        <SectionTitle
          classes="text-center"
          slogan="After the Cap and Gown: Discover Where"
          title="Our Alumni Thrive"
        />

        <div className="bg-white">
          <Grid pt={2}>
            <Marquee direction="left" speed={100} delay={5}>
              {images.map((src, index) => (
                <Grid
                  key={index}
                  className="image_wrapper"
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <img src={src} alt={`Logo ${index + 1}`} />
                </Grid>
              ))}
            </Marquee>
          </Grid>
        </div>
      </Grid>
    </>
  );
}

export default Accreditation;
