import React from "react";
import { Container, Grid, Box, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import { isMediumScreens } from "../../Constants/Constants";
import ScrollAnimation from "react-animate-on-scroll";

const AboutSection = styled(Box)({
  textAlign: "center",
  marginBottom: "48px",
});

const AboutUs = () => {
  const isMediumScreen = useMediaQuery(isMediumScreens);
  return (
    <Grid py={isMediumScreen ? "0px" : "50px"}>
      <Container maxWidth="lg">
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
        >
          <AboutSection>
            <h3>About Paris American International University</h3>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={4}
              pt={2}
            >
              <Grid item xs={12} sm={6} md={4}>
                <img src="/images/logo/PAIU Logos.png" alt="Main Logo" />
              </Grid>
              <Grid item xs={12} sm={6} md={8} textAlign={"left"}>
                <p style={{ fontSize: "16px" }}>
                  Paris American International University (PAIU) is a renowned
                  educational institution known for offering bachelors, masters,
                  PhD, and doctorate programs that cater to the academic and
                  professional aspirations of students worldwide. Located in the
                  heart of UAE, one of the most culturally rich cities in the
                  world, PAIU combines the best of European academic traditions
                  with a modern, innovative approach to education. Each program
                  is designed to equip students with theoretical knowledge and
                  practical skills, preparing them for successful careers in
                  their chosen fields. The degrees and certifications awarded by
                  PAIU are recognized globally, adherence to Ministry of France
                  international academic standards. The university employs a
                  blend of on campus learning and online learning solutions,
                  offering flexibility and accessibility to students from
                  different backgrounds. Also we provide robust career services,
                  including internship placements, job search assistance, and
                  professional development workshops.
                </p>
              </Grid>
            </Grid>
          </AboutSection>
          <Grid
            container
            spacing={4}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            textAlign={"center"}
          >
            <Grid item xs={12} sm={6} md={4} lg={2} textAlign={"center"}>
              <h5 style={{ marginBottom: "0px" }}>4000+</h5>
              <p>Students Graduated</p>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <h5 style={{ marginBottom: "0px" }}>32+</h5>
              <p>Different Nationalities</p>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <h5 style={{ marginBottom: "0px" }}>PhD</h5>
              <p>Qualified Faculties</p>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <h5 style={{ marginBottom: "0px" }}>1 Year</h5>
              <p>Accelerated Learning</p>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <h5 style={{ marginBottom: "0px" }}>
                1<sup>st</sup>
              </h5>
              <p>International Education</p>
            </Grid>
          </Grid>
        </ScrollAnimation>
      </Container>
    </Grid>
  );
};

export default AboutUs;
