import React from "react";
import BannerTwo from "../../components/banner/BannerTwo";
import HomeTwoService from "../../components/home-two/HomeTwoService";
import Network from "../../components/about/Network";
import OurPrograms from "../../components/home-two/OurPrograms";
import Faq from "./Faq";
import AcademicHighlight from "./AcademicHighlight";
import Benefits from "./Benefits";
import HeaderOne from "../../common/header/HeaderOne";
import AboutUs from "./AboutUs";
import Highlights from "./Highlights";
import Credentials from "./Credentials";
import ContactUs from "../innerpages/ContactUs";
import Accreditation from "./Accreditation";
import { Fab } from "@mui/material";
import { WhatsappIcon } from "../../Constants/Constants";
import { green } from "@mui/material/colors";

const HomeTwo = () => {
  return (
    <>
      {/* <SEO title="Home 2" /> */}

      {/* <HeaderTwo styles="header-transparent header-style-2" /> */}
      
      <HeaderOne styles="header-transparent header-style-2" />

      <BannerTwo />

      <Accreditation />

      <OurPrograms />

      <Network />

      <HomeTwoService />

      <AboutUs />

      <Benefits />

      <AcademicHighlight />

      <Credentials />

      <Highlights />

      <Faq />

      <ContactUs />

      {/* <Fab 
        color="white" 
        aria-label="whatsapp" 
        sx={{bgcolor: green[500],
            '&:hover': {
              bgcolor: green[600],
            },}}
        style={{ position: 'fixed', bottom: 20, right: 20 }}
        href="https://api.whatsapp.com/send/?phone=971569456777&text&type=phone_number&app_absent=0"
      >
        <WhatsappIcon />
      </Fab> */}

      {/* <FooterOne /> */}
    </>
  );
};

export default HomeTwo;
