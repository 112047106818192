import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const CustomForm = ({ formStyle }) => {
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phone: "",
    course_title: "",
    country: "",
  });

  const [errors, setErrors] = useState({});
  const [result, setResult] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    fetchIPAddress();
  }, []);
  

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value,
    });
  };

  const validateForm = () => {
    let newErrors = {};

    if (!formData.fullname) {
      newErrors.fullname = "Please fill out your name.";
    }
    if (!formData.email) {
      newErrors.email = "Please fill out your email.";
    }
    if (!formData.phone) {
      newErrors.phone = "Please fill out your phone number.";
    }
    if (!formData.course_title) {
      newErrors.course_title = "Please select a course.";
    }
    if (!formData.country) {
      newErrors.country = "Please select a country.";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const portalId = "143713869"; // Replace with your portalId
    const formId = "6e5e77c2-39c1-4e31-850e-b1dcd8abccac"; // Replace with your formId

    const payload = {
      fields: [
        { name: "firstname", value: formData.fullname },
        { name: "email", value: formData.email },
        { name: "phone", value: formData.phone },
        { name: "field_of_study", value: formData.course_title }, // Updated field name
        { name: "country_i_live_in", value: formData.country }, 
        // Updated field name
      ],
      context: {
        ipAddress: ipAddress, // Add the IP address here
      },
    };

    const endpoint = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        window.location.href = "/thankyou";
        setResult(true);
        setFormData({
          fullname: "",
          email: "",
          phone: "",
          course_title: "",
          country: "",
        });
        // setTimeout(() => setResult(false), 5000);
      } else {
        alert("There was an error submitting the form.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("There was an error submitting the form.");
    }
  };

  return (
    <form className={`${formStyle}`} onSubmit={handleSubmit} style={{ paddingTop: '70px' }}>
      <div className="col-lg-12">
        <div className="form-group">
          <input
            type="text"
            className={`form-control form-control-lg ${
              errors.fullname ? "border-red-500" : ""
            }`}
            name="fullname"
            placeholder="Name*"
            value={formData.fullname}
            onChange={handleChange}
            required
          />
          {errors.fullname && <p className="text-danger">{errors.fullname}</p>}
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <input
            type="email"
            className={`form-control form-control-lg ${
              errors.email ? "text-danger" : ""
            }`}
            name="email"
            placeholder="Email*"
            value={formData.email}
            onChange={handleChange}
            required
          />
          {errors.email && <p className="text-danger">{errors.email}</p>}
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <PhoneInput
            country={"ae"}
            value={formData.phone}
            onChange={handlePhoneChange}
            // inputClass={`form-control form-control-lg ${
            //   errors.phone ? "border-red-500" : ""
            // }`}
            required  
          />
          {errors.phone && (
            <p className="text-danger text-sm">{errors.phone}</p>
          )}
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <Form.Select
            name="course_title"
            value={formData.course_title}
            onChange={handleChange}
            className={`form-control custom-select form-control-lg ${
              errors.course_title ? "border-red-500" : ""
            }`}
            required
          >
            <option value="">Select Course</option>
            <option value="MSc in Data Science">Masters in Data Science</option>
            <option value="MSc in Cyber Security">
              Masters in Cyber Security
            </option>
            <option value="MBA ( Corporate Finance )">
              Masters in Corporate Finance
            </option>
            <option value="MSc in Information Technology">
              Masters in Information Technology
            </option>
            <option value="MBA ( Marketing Management )">
              Masters in Marketing Management
            </option>
            <option value="MBA (on-campus)">
              Masters in Business Administration
            </option>
            <option value="MBA ( Legal Business Studies )">
              Masters in Legal Business Studies
            </option>
            <option value="MBA ( Human Resource Management )">
              Masters in Human Resource Management
            </option>
          </Form.Select>
          {errors.course_title && (
            <p className="text-danger text-sm">{errors.course_title}</p>
          )}
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <Form.Select
            name="country"
            value={formData.country}
            onChange={handleChange}
            className="custom-select"
          >
            <option value="">Select Country</option>
            <option value="UAE">UAE</option>
            <option value="Saudi Arabia">Saudi Arabia</option>
            <option value="Qatar">Qatar</option>
            <option value="Kuwait">Kuwait</option>
            <option value="Bahrain">Bahrain</option>
            <option value="India">India</option>
            <option value="Bangladesh">Bangladesh</option>
            <option value="Lebanon">Lebanon</option>
            <option value="Nepal">Nepal</option>
            <option value="Kenya">Kenya</option>
            <option value="Mauritius">Mauritius</option>
            <option value="Maldives">Maldives</option>
            <option value="Sri Lanka">Sri Lanka</option>
            <option value="Cyprus">Cyprus</option>
            <option value="Pakistan">Pakistan</option>
          </Form.Select>
          {errors.country && <p className="text-danger">{errors.country}</p>}
        </div>
      </div>

      <div className="col-lg-12">
        <button className="rn-btn edu-btn w-100" type="submit">
          <span>Submit</span>
          {/* <i className="icon-arrow-right-line-right"></i> */}
        </button>
      </div>

      {/* {result ? (
        <div className="form-group">
          <p className="success-message">Thanks for your query. We will contact you soon.</p>
        </div>
      ) : null} */}
    </form>
  );
};

export default CustomForm;
