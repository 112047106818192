import React from "react";
import { Stack, useMediaQuery } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import ScrollAnimation from "react-animate-on-scroll";
import { isMediumScreens } from "../../Constants/Constants";

const AcademicHighlight = () => {
  const isMediumScreen = useMediaQuery(isMediumScreens);

  return (
    <>
      <div className="edu-purchase-guide-area edu-purchase-guide edu-section-gap bg-color-white">
        <div className="container">
          <div className="row g-5">
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <div
                className="col-lg-12"
                style={{ textAlign: isMediumScreen ? "left" : "center" }}
              >
                <div className="content">
                  <h4 className="title">
                    Be a proud member of the PAIU alumni network for life
                  </h4>
                  <p>
                    Upon completion of the program, participants will be
                    eligible to receive the alumni status from Paris American
                    International University.
                  </p>
                  <p>Benefits Include:</p>
                  <Stack spacing={2} px={isMediumScreen ? 0 : 12}>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <VerifiedIcon
                        sx={{ color: "#0c0d77", height: "25px", width: "25px" }}
                      />
                      <p>A global network of over 40,000 alumni</p>
                    </Stack>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <VerifiedIcon
                        sx={{ color: "#0c0d77", height: "25px", width: "25px" }}
                      />
                      <p>
                        Invitations to exclusive PAIU alumni events and
                        networking opportunities
                      </p>
                    </Stack>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <VerifiedIcon
                        sx={{ color: "#0c0d77", height: "25px", width: "25px" }}
                      />
                      <p>
                        Alumni communications, including the Community Connect
                        e-newsletter
                      </p>
                    </Stack>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <VerifiedIcon
                        sx={{ color: "#0c0d77", height: "25px", width: "25px" }}
                      />
                      <p>
                        Access to two editions per year of Uniview, the
                        University's digital magazine
                      </p>
                    </Stack>

                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <VerifiedIcon
                        sx={{ color: "#0c0d77", height: "25px", width: "25px" }}
                      />
                      <p>
                        Easy access to the university campus in UAE and Paris
                      </p>
                    </Stack>
                  </Stack>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </>
  );
};
export default AcademicHighlight;
