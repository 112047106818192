import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const FeatureItems = [
    {
        title: 'Advanced Knowledge in Specialization',
        info: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        image: 'book.png',
        bgColor: 'card-bg',
        link: '#'
    },
    {
        title: ' Critical Thinking and Problem Solving',
        info: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        image: 'problem-solving.png',
        bgColor: 'card-bg-2',
        link: '#'
    },
    // {
    //     title: ' Technical Proficiency',
    //     info: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    //     image: 'feature-image-03.png',
    //     bgColor: 'card-bg-3',
    //     link: '#'
    // },
    // {
    //     title: ' Research and Analytical Skills',
    //     info: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    //     image: 'feature-image-03.png',
    //     bgColor: 'card-bg-3',
    //     link: '#'
    // },
   
    {
        title: ' Ethical and Professional Standards',
        info: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        image: 'professional-development.png',
        bgColor: 'card-bg-3',
        link: '#'
    },
     {
        title: 'Leadership and Management Competencies',
        info: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        image: 'team-leader.png',
        bgColor: 'card-bg-4',
        link: '#'
    },
    {
        title: ' Global Perspective and Cultural Awareness',
        info: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        image: 'global-conservation.png',
        bgColor: 'card-bg-5',
        link: '#'
    },
    {
        title: 'Communication and Interpersonal Skills',
        info: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        image: 'conversation.png',
        bgColor: 'card-bg-6',
        link: '#'
    },
];

const FeatureOne = ( { wrapperClass, alignment, style } ) => {
    return (
        <div className={ `row ${ wrapperClass || 'g-5 mt--20' }` }>
            { FeatureItems.map( ( data , i ) => (
                <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    className={ `col-lg-4 col-md-6 col-12 ${ alignment ? alignment : '' } ${ style ? style : '' }` }
                    animateOnce={ true }
                    key={ i }
                >
                    <div className={ `service-card service-card-2 ${ data.bgColor }` }>
                        <div className="inner">
                            <div className="icon">
                                <a>
                                    <img src={`${process.env.PUBLIC_URL}/images/feature/feature-01/${ data.image }`} alt="Feature Thumb" style={{height:"50px"}} />
                                </a>
                                <div className="shape-list">
                                    <img className="shape shape-1" src="/images/feature/feature-01/feature-icon-01.png" alt="Shape Particle" />
                                    <img className="shape shape-2" src="/images/feature/feature-01/feature-icon-02.png" alt="Shape Particle" />
                                    <img className="shape shape-3" src="/images/feature/feature-01/feature-icon-03.png" alt="Shape Particle" />
                                </div>
                            </div>
                            <div className="content">
                                <h6 className="title">{ data.title }</h6>
                                {/* <p className="description">{ data.info }</p> */}
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            ) ) }
        </div>
    )
}

export default FeatureOne;