import React from "react";
import { Box, Container, Grid, Stack, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import { isMediumScreens } from "../../Constants/Constants";
import ScrollAnimation from "react-animate-on-scroll";
import FeatureOne from "../../components/feature/FeatureOne";
import { Link } from "react-scroll";

const HighlightNumber = styled("span")({
  fontSize: "53px",
  color: "#7152E9",
  fontWeight: "bold",
  marginRight: "10px",
});

const Benefits = () => {
  const isMediumScreen = useMediaQuery(isMediumScreens);
  return (
    <Container sx={{ textAlign: "center", mt: 4 }}>
      <SectionTitle
        classes="text-center"
        // slogan = "Course Categories"
        title=" On completion of this course, you'll walk away with:"
      />

      {/* <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={ true }
                        >
                                <Grid container spacing={1} direction="column">
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" textAlign={"left"} spacing={3}>
            <HighlightNumber>1</HighlightNumber>

            <p style={{ fontSize: '20px' }}>             Advanced Knowledge in Specialization
            </p>

          </Stack>
        </Grid> <Grid item xs={12}>
          <Stack direction="row" alignItems="center" textAlign={"left"} spacing={2} >
            <HighlightNumber>2</HighlightNumber>

            <p style={{ fontSize: '20px' }}>             Critical Thinking and Problem-Solving
            </p>

          </Stack>
        </Grid> <Grid item xs={12}>
          <Stack direction="row" alignItems="center" textAlign={"left"} spacing={2} >
            <HighlightNumber>3</HighlightNumber>

            <p style={{ fontSize: '20px' }}>             Research and Analytical Skills
            </p>

          </Stack>
        </Grid> <Grid item xs={12}>
          <Stack direction="row" alignItems="center" textAlign={"left"} spacing={2} >
            <HighlightNumber>4</HighlightNumber>

            <p style={{ fontSize: '20px' }}>             Leadership and Management Competencies
            </p>

          </Stack>
        </Grid> <Grid item xs={12}>
          <Stack direction="row" alignItems="center" textAlign={"left"} spacing={2} >
            <HighlightNumber>5</HighlightNumber>

            <p style={{ fontSize: '20px' }}>            Technical Proficiency
            </p>

          </Stack>
        </Grid> <Grid item xs={12}>
          <Stack direction="row" alignItems="center" textAlign={"left"} spacing={2}>
            <HighlightNumber>6</HighlightNumber>

            <p style={{ fontSize: '20px' }}>             Ethical and Professional Standards
            </p>

          </Stack>
        </Grid> <Grid item xs={12}>
          <Stack direction="row" alignItems="center" textAlign={"left"} spacing={2}>
            <HighlightNumber>7</HighlightNumber>

            <p style={{ fontSize: '20px' }}>             Global Perspective and Cultural Awareness
            </p>

          </Stack>
        </Grid> <Grid item xs={12}>
          <Stack direction="row" alignItems="center" textAlign={"left"}  spacing={2}>
            <HighlightNumber>8</HighlightNumber>

            <p style={{ fontSize: '20px' }}>             Communication and Interpersonal Skills
            </p>

          </Stack>
        </Grid>
      </Grid>
                        </ScrollAnimation> */}
      <FeatureOne />
      <Box textAlign={"center"} pt={3}>
<Link
                    // className="edu-btn"
                    to="contact-us"
                    spy={true}
                    smooth={true}
                    duration={200}
                  >
                    <button class="btnn">
                      <i class="animation"></i>Shedule a free consultation<i class="animation"></i>
                    </button>
                  </Link>
</Box>
    </Container>
  );
};

export default Benefits;
