import { ChatBubble } from '@mui/icons-material';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TaskIcon from '@mui/icons-material/Task';
import EuroIcon from '@mui/icons-material/Euro';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const items = [
    {
        title: '88%',
        info: 'of graduates received a job offer within three months of completing their program',
        icon: <CalendarMonthIcon sx={{color:"#0c0d77", height:"40px",width:"40px"}} />,
        link: '#'
    },
    {
        title: '78%',
        info: 'successfully transitioned to new careers, whether by sector, geographical location, or job function.',
        icon: <TaskIcon sx={{color:"#0c0d77", height:"40px",width:"40px"}} />,
        link: '#'
    },
    {
        title: 'EUR 113,400',
        info: 'The overall average annual salary reached EUR 113,400, reflecting strong earning potential.',
        icon: <EuroIcon sx={{color:"#0c0d77", height:"40px",width:"40px"}}/>,
        link: '#'
    },
    {
        title: '260 +',
        info: 'Over 260 unique recruiting partners actively collaborate with us to provide diverse career opportunities.',
        icon: <AccountBalanceIcon sx={{color:"#0c0d77", height:"40px",width:"40px"}}/>,
        link: '#'
    },
];

const CategoryTwo = ( { wrapperClass, styleClass } ) => {
    return (
        <div className={ `row ${ wrapperClass || 'g-5 mt--25' }` }>
            { items.map( ( data , i ) => (
                <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    className={ `col-lg-3 col-md-6 col-sm-6 col-12 ${ styleClass ? styleClass : '' }` }
                    animateOnce={ true }
                    key={ i }
                >
                    <div className="service-card service-card-4">
                        <div className="inner">
                            <div className="icon">
                                {data.icon}
                            </div>
                            <div className="content">
                                <h6 className="title" style={{ color: '#7152E9 !important' }} ><a href={ data.link }>{ data.title }</a></h6>
                                <p className="description">{ data.info }</p>
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            ) ) }
             <style jsx>{`
             
                .service-card-4:hover .inner .icon svg {
                    color: white !important;
                }
            `}</style>
        </div>
    )
}

export default CategoryTwo;