import React from "react";
import { Link } from "react-scroll";
import {
  GroupIcon,
  isSmallScreens,
  ProgressIcon,
} from "../../Constants/Constants";
import { useMediaQuery } from "@mui/material";

const BannerTwo = () => {
  const isSmallScreen = useMediaQuery(isSmallScreens);
  return (
    <div className="slider-area banner-style-2 bg-image d-flex align-items-center">
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="inner">
              <div className="content">
                <h1 className="title">
                  Get the Key to Unlock Your Future with High Demanding{" "}
                  <span style={{ color: "#0c0d77" }}>
                    {" "}
                    Masters Degree in UAE
                  </span>
                </h1>
                <p className="description">
                  By enrolling in our master's program, you will be prepared to
                  navigate the complexities of an ever-evolving workforce,
                  ensuring success in your chosen field. Whether you aim to lead
                  in business, innovate in technology, or secure digital
                  infrastructures, PAIU provides the comprehensive education and
                  practical experience required to achieve your career goals.
                </p>
                <div>
                  <Link to="contact-us" spy={true} smooth={true} duration={200}>
                    <button class="btnn">
                      <i class="animation"></i>Apply for scholarship
                      <i class="animation"></i>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="banner-thumbnail">
              <img
                className="girl-thumb"
                src="/images/brand-logos/hero-pic-sec.webp"
                alt="Girl Images"
              />
            </div>
            {/* <div className="banner-bg d-lg-block d-none">
              <img
                className="girl-bg"
                src="/images/banner/banner-02/girl-bg.png"
                alt="Girl Background"
              />
            </div> */}
          </div>
        </div>

        <div className="shape-dot-wrapper shape-wrapper d-xl-block">
          <div className="shape-image shape-image-2 bounce-slide">
            <ProgressIcon
              width={isSmallScreen ? 70 : 123}
              height={isSmallScreen ? 70 : 123}
            />
          </div>
          <div className="shape-image shape-image-3 bounce-slide">
            <GroupIcon
              width={isSmallScreen ? 70 : 109}
              height={isSmallScreen ? 70 : 135}
            />
          </div>
        </div>
      </div>
      <style jsx>{`
        .btnn {
          outline: 0;
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          background: #0c0d77;
          min-width: 200px;
          border: 0;
          border-radius: 4px;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          box-sizing: border-box;
          padding: 16px 20px;
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          letter-spacing: 1.2px;
          overflow: hidden;
          cursor: pointer;
        }

        .btnn:hover {
          opacity: 0.95;
        }

        .btnn .animation {
          border-radius: 100%;
          animation: ripple 0.6s linear infinite;
        }

        @keyframes ripple {
          0% {
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
              0 0 0 20px rgba(255, 255, 255, 0.1),
              0 0 0 40px rgba(255, 255, 255, 0.1),
              0 0 0 60px rgba(255, 255, 255, 0.1);
          }

          100% {
            box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1),
              0 0 0 40px rgba(255, 255, 255, 0.1),
              0 0 0 60px rgba(255, 255, 255, 0.1),
              0 0 0 80px rgba(255, 255, 255, 0);
          }
        }
      `}</style>
    </div>
  );
};

export default BannerTwo;
