import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../sectionTitle/SectionTitle";
import { Stack, useMediaQuery } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { isSmallScreens } from "../../Constants/Constants";

const topIndustries = [
  "Finance and Banking",
  "Technology",
  "Healthcare",
  "Manufacturing",
  "Government",
  "Others*",
];

const topFunctions = [
  "Data Analysis/ Data Engineering",
  "IT Management",
  "Security Analysis",
  "Compliance and Auditing",
  "General Management",
  "Others*",
];

const Network = () => {
  const isSmallScreen = useMediaQuery(isSmallScreens);
  return (
    <div className="edu-about-area about-style-3 edu-section-gap bg-image">
      <div className="container eduvibe-animated-shape">
        <div className="row g-5 align-items-center">
          <div className={`col-lg-6 ${!isSmallScreen ? "pr--80" : ""}`}>
            <ScrollAnimation
              animateIn="fadeIn"
              animateOut="fadeInOut"
              className="gappery-wrapper"
              delay={150}
              animateOnce={true}
            >
              <div className="row g-5 align-items-end">
                <div className="col-lg-12">
                  <div className="gallery-image gallery-image-3 text-center">
                    <img
                      src="/images/brand-logos/graph.webp"
                      alt="Gallery Images"
                    />
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          </div>

          <div className="col-lg-6">
            <div className="inner">
              <SectionTitle
                classes="text-start"
                slogan="Past Participant Profiles"
                title="Network and Learn with a Diverse Cohort"
              />

              <div className="feature-style-5 row g-5">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="col-lg-12 col-xl-6"
                  animateOnce={true}
                >
                  <div className="edu-feature-list">
                    <div className="content">
                      <Stack direction={"column"} spacing={2}>
                        <h6 className="title">Top Industries</h6>
                        {topIndustries.map((industry, index) => (
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                            key={index}
                          >
                            <CheckCircleOutlineIcon
                              sx={{
                                color: "#0c0d77",
                                height: "20px",
                                width: "20px",
                              }}
                            />
                            <p className="description">{industry}</p>
                          </Stack>
                        ))}
                        <p className="description">
                          *Others include Pharma/Biotech, Retail, Technology,
                          Banking, Healthcare, Advertising/Marketing/PR amongst
                          others.
                        </p>
                      </Stack>
                    </div>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="col-lg-12 col-xl-6"
                  animateOnce={true}
                >
                  <div className="edu-feature-list">
                    <div className="content">
                      <Stack direction={"column"} spacing={2}>
                        <h6 className="title">Top Functions</h6>
                        {topFunctions.map((industry, index) => (
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                            key={index}
                          >
                            <CheckCircleOutlineIcon
                              sx={{
                                color: "#0c0d77",
                                height: "20px",
                                width: "20px",
                              }}
                            />
                            <p className="description">{industry}</p>
                          </Stack>
                        ))}
                        <p className="description">
                          *Others include Consulting, Manufacturing, Operations,
                          and R&D, amongst others
                        </p>
                      </Stack>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Network;
