import { useState } from "react";
import { Link } from "react-router-dom";
import HeaderSticky from "./HeaderSticky";
import ResponsiveMenu from "./ResponsiveMenu";
import { motion } from "framer-motion";
import "animate.css";

const HeaderOne = ({ styles, disableSticky }) => {
  const [offcanvasShow, setOffcanvasShow] = useState(false);
  const [searchPopup, setSearchPopup] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prevState) => !prevState);
  };

  const onSearchHandler = () => {
    setSearchPopup((prevState) => !prevState);
  };

  if (searchPopup) {
    document.body.classList.add("search-popup-active");
  } else {
    document.body.classList.remove("search-popup-active");
  }

  const [alertVisible, setAlertVisible] = useState(true);

  const sticky = HeaderSticky(118);
  const classes = sticky ? "sticky" : "";
  const stickyStatus = disableSticky ? "" : " header-sticky";
  return (
    <>
      <header className={`edu-header ${styles || ""} ${classes || ""}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-6">
              <div className="logo">
                <Link to={process.env.PUBLIC_URL + "/"}>
                  <img
                    className="logo-light"
                    src="/images/brand-logos/PAIU.png"
                    alt="Main Logo"
                  />
                </Link>
              </div>
            </div>

            <div className="col-lg-8 d-none d-xl-block">
              <nav className="mainmenu-nav d-none d-lg-block">
                {/* <Nav /> */}
              </nav>
            </div>

            {/* <div className="col-lg-6 col-xl-2 col-md-6 col-6">
                            <div className="header-right d-flex justify-content-end">
                                <div className="header-quote">
                                    <div className="quote-icon quote-search">
                                        <button className="search-trigger" onClick={ onSearchHandler }><i className="ri-search-line"></i></button>
                                    </div>
                                    <div className="quote-icon quote-user">
                                        <Link to={process.env.PUBLIC_URL + "/login-register"}><i className="ri-user-line"></i></Link>
                                    </div>
                                    <div className="hamberger quote-icon d-block d-xl-none">
                                        <button className="hamberger-button" onClick={ onCanvasHandler }>
                                            <i className="ri-menu-line"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </header>
      {/* <motion.div
                initial={{ opacity: 0, y: -50 }}  // Start off-screen and transparent
                animate={{ opacity: 1, y: 0 }}   // Animate to fully visible and on-screen
                transition={{ duration: 2 }}   // Duration of the animation
                className="alert-container"
            >
                <div className="alert alert-warning alert-dismissible fade show text-center" role="alert">
                    <strong>30% DISCOUNT!</strong> <br />
                    Hurry Up and Grab the Offer!<br />
                    Don't miss out on this exclusive discount. Visit our website to learn more and take advantage of this limited-time offer.
                    
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                    ></button>
                </div>
            </motion.div> */}
      {alertVisible && (
        <div class="alert-container animate__animated animate__fadeInDown">
          <div
            className="alert alert-warning alert-dismissible fade show text-center"
            role="alert"
          >
            <strong>30% DISCOUNT!</strong> <br />
            Hurry Up and Grab the Offer!
            <br />
            Don't miss out on this exclusive discount. Visit our website to
            learn more and take advantage of this limited-time offer.
            <button
              onClick={() => setAlertVisible(false)}
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        </div>
      )}
      <ResponsiveMenu
        show={offcanvasShow}
        onClose={onCanvasHandler}
        showSearch={searchPopup}
        onSearch={onSearchHandler}
      />
    </>
  );
};

export default HeaderOne;
