import { Box, Card, CardContent, Stack } from "@mui/material";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import ForumIcon from "@mui/icons-material/Forum";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SchoolIcon from "@mui/icons-material/School";
import { Link } from "react-scroll";

const FeatureItems = [
  {
    title: "Master's in Business Administration (MBA)",
    info: "Our MBA program at PAIU is designed to transform you into a global business leader. Through a curriculum that integrates both theoretical and practical knowledge, you will gain insights into key business disciplines including finance, marketing, operations, and strategic management.This comprehensive approach ensures you are well-prepared to tackle the complexities of the modern business world",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
  },
  {
    title: "MSc in Data Science",
    info: "Our MSc in Data Science equips you with the necessary skills to analyze and interpret complex data. This program focuses on machine learning, statistical methods, data visualization, and data-driven decision-making, ensuring you are ready to meet the demands of this rapidly evolving field.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
  },
  {
    title: "MSc in Information Technology",
    info: "The MSc in Information Technology at PAIU provides an in-depth understanding of IT systems, software development, and IT project management. This program is ideal for those looking to enhance their technical skills and pursue a career in the ever-evolving tech industry.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
  },
  {
    title: "MSc in Cyber Security",
    info: "Prepare to protect and secure digital infrastructure with our MSc in Cyber Security. This program covers essential topics such as cryptography, network security, cyber forensics, and ethical hacking, equipping you with the skills to defend against cyber threats.",
    lang: "English",
    duration: "1 Year / 12 Months (Fast Track) | 2 Years / 24 Months (Regular)",
    campus: "On- Campus | Online",
    link: "#",
  },
];

const FeatureTwo = ({ wrapperClass, bgColor, alignment, style }) => {
//   const handleDownload = () => {
//     const link = document.createElement("a");
//     link.href = `${process.env.PUBLIC_URL}/Online-MBA_Program-Brochure_compressed.pdf`;
//     link.download = "Online-MBA_Program-Brochure_compressed.pdf";
//     link.click();
//   };

  return (
    <div
      className={`row eduvibe-about-one-service ${
        wrapperClass || "g-5 mt--20"
      }`}
    >
      {FeatureItems.map((data, i) => (
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          className={`col-lg-6 col-md-6 col-12${style ? " " + style : ""}`}
          animateOnce={true}
          key={i}
        >
          {/* <div
            className={`service-card service-card-3 ${
              alignment ? " " + alignment : "text-left"
            } ${data.shape} ${bgColor || ""} `}
          >
            <div className="inner">
              <div className="content">
                <h6 className="title">
                  <a href={data.link}>{data.title}</a>
                </h6>

                <p className="description">{data.info}</p>
                <Stack spacing={2} pb={2}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <ForumIcon sx={{ height: "30px", width: "30px" }} />
                    <p>{data.lang}</p>
                  </Stack>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <CalendarMonthIcon sx={{ height: "30px", width: "30px" }} />
                    <p>{data.duration}</p>
                  </Stack>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <SchoolIcon sx={{ height: "30px", width: "30px" }} />
                    <p>{data.campus}</p>
                  </Stack>
                </Stack>

                <div className="read-more-btn">
                  <a
                    className="btn-transparent sm-size heading-color"
                    href={data.link}
                  >
                    Download Brochure
                    <i className="icon-arrow-right-line-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div> */}
          <Card
            style={{ margin: "auto", borderRadius: "10px" }}
            className={`service-card service-card-3 ${
              alignment ? " " + alignment : "text-left"
            } ${data.shape} ${bgColor || ""} `}
          >
            <Box
              sx={{
                backgroundColor: "#0c0d77",
                padding: "20px",
                borderRadius: "10px 10px 0 0",
                textAlign: "center",
              }}
            >
              <h5
                className="title"
                style={{ color: "white", marginBottom: "0px" }}
              >
                {data.title}
              </h5>
            </Box>
            <CardContent>
              <p className="description">{data.info}</p>
              <Stack spacing={2} pb={2}>
                {/* <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <ForumIcon
                    sx={{ height: "30px", width: "30px", color: "#0c0d77" }}
                  />
                  <p>{data.lang}</p>
                </Stack> */}
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <CalendarMonthIcon
                    sx={{ height: "30px", width: "30px", color: "#0c0d77" }}
                  />
                  <p>1 Year / 12 Months (Fast Track) <br /> 2 Years / 24 Months (Regular)</p>
                </Stack>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <SchoolIcon
                    sx={{ height: "30px", width: "30px", color: "#0c0d77" }}
                  />
                  <p>{data.campus}</p>
                </Stack>
              </Stack>
              {/* 
                <div className="read-more-btn">
                  <a
                    className="btn-transparent sm-size heading-color"
                    href={data.link}
                  >
                    Download Brochure
                    <i className="icon-arrow-right-line-right"></i>
                  </a></div> */}
              <Link
                    className="btn-transparent sm-size heading-color"
                    to="contact-us"
                    spy={true}
                    smooth={true}
                    duration={200}
                  >
                     Download Brochure
                     <i className="icon-arrow-right-line-right"></i>
                  </Link>
              {/* <a
                className="btn-transparent sm-size heading-color"
                onClick={handleDownload}
              >
                Download Brochure
                <i className="icon-arrow-right-line-right"></i>
              </a> */}
            </CardContent>
          </Card>
        </ScrollAnimation>
      ))}
    </div>
  );
};

export default FeatureTwo;
