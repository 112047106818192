import React, { useState, useContext } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Element } from "react-scroll";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";

const accordionItems = [
  {
    id: 1,
    title:
      "What are the eligibility criteria for enrolling in PAIU's master's programs?",
    body: "<p>To enroll in PAIU's master's programs, applicants must hold a bachelor’s degree from a recognized institution. Specific programs may have additional prerequisites, such as relevant work experience or specific undergraduate coursework. For detailed eligibility requirements, please refer to the specific program's page on our website.</p>",
  },
  {
    id: 2,
    title: "What documents are required for the application process?",
    body: "<p>The essential documents include:</p><ul><li>A completed application form</li><li>Official transcripts from all previously attended institutions</li><li>A copy of your passport or national ID</li><li>Proof of English language proficiency (if applicable)</li><li>A personal statement or statement of purpose</li><li>Letters of recommendation (usually two)</li></ul>",
  },
  {
    id: 3,
    title:
      "Do I need to submit my academic transcripts during the application process?",
    body: "<p>Yes, you need to submit official academic transcripts from all post-secondary institutions you have attended. These transcripts should detail your academic history, including all courses taken and grades received.</p>",
  },
  {
    id: 4,
    title: "Is there an application deadline for PAIU programs?",
    body: "<p>PAIU offers multiple intake periods throughout the year, allowing for flexible application deadlines. However, it is recommended to apply as early as possible to secure a spot and allow time for processing. Check the specific program page for upcoming deadlines.</p>",
  },
  {
    id: 5,
    title: "Are there any scholarships or financial aid options available?",
    body: "<p>Yes, PAIU offers a range of scholarships and financial aid options to eligible students. These are awarded based on academic merit, financial need, or specific criteria related to the program of study. Please visit our scholarships page for more details.</p>",
  },
  {
    id: 6,
    title: "Can I transfer credits from another university to PAIU?",
    body: "<p>Yes, PAIU allows the transfer of credits from accredited institutions. However, the acceptance of credits is subject to approval by the academic department and depends on the relevance and level of the coursework.</p>",
  },
  {
    id: 7,
    title: "What are the language requirements for the programs offered?",
    body: "<p>All programs at PAIU are conducted in English. Therefore, proficiency in English is essential.</p>",
  },
  {
    id: 8,
    title: "How can I apply for a program at PAIU?",
    body: "<p>To apply, visit the PAIU website and fill out the online application form. Ensure you have all required documents ready for upload. You will also need to pay a non-refundable application fee. Follow the detailed instructions provided on the application portal.</p>",
  },
  {
    id: 9,
    title: "Can I apply to multiple programs at once?",
    body: "<p>Yes, applicants can apply to multiple programs. However, each application must be submitted separately, and separate application fees will apply.</p>",
  },
  {
    id: 10,
    title: "What kind of support services are available for students?",
    body: "<p>PAIU offers a wide range of support services, including academic advising, career counseling, IT support, and access to a digital library. We also provide support for international students, including visa assistance and orientation programs.</p>",
  },
  {
    id: 11,
    title: "Are there online learning resources available for students?",
    body: "<p>Yes, PAIU provides comprehensive online learning resources, including access to digital libraries, e-books, and online journals. Students can also participate in live online classes and webinars, offering flexible learning opportunities.</p>",
  },
];

const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      aria-expanded={isCurrentEventKey ? true : false}
      className="edu-accordion-button"
    >
      {children}
    </button>
  );
};

const Faq = () => {
  const [activeId, setActiveId] = useState(null);
  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }
  return (
    <Element
      name="faq"
      className="landing-demo-faq-wrapper edu-accordion-area accordion-shape-1 bg-color-white"
    >
      <div
        className="container eduvibe-animated-shape"
        style={{ paddingTop: "130px" }}
      >
        <div className="row">
          <div className="col-lg-12">
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              className="pre-section-title text-center"
              animateOnce={true}
            >
              <span className="color-primary pretitle">Faq question</span>
              <h3 className="title">Have Any Questions?</h3>
            </ScrollAnimation>
          </div>
        </div>
        <div className="row g-5 mt--10">
          <div className="col-xl-8 offset-xl-2">
            <Accordion
              bsPrefix="landing-demo-faq edu-accordion-02 variation-2 landing-page-accordion"
              defaultActiveKey="null"
              flush
            >
              {accordionItems.map((accordion, i) => (
                <Accordion.Item
                  eventKey={i.toString()}
                  key={i}
                  onClick={() => toggleActive(i.toString())}
                  bsPrefix={`edu-accordion-item ${
                    activeId === i.toString() ? "bg-active" : ""
                  }`}
                >
                  <div className="edu-accordion-header">
                    <CustomToggle eventKey={i.toString()}>
                      {accordion.title}
                    </CustomToggle>
                  </div>
                  <Accordion.Body bsPrefix="edu-accordion-body">
                    <div
                      dangerouslySetInnerHTML={{ __html: accordion.body }}
                    ></div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default Faq;
