import React from 'react';
import { Facebook, Twitter, Instagram, LinkedIn, YouTube } from '@mui/icons-material';
import XIcon from '@mui/icons-material/X';

const ThankYouPage = () => {
  return (
    <div className="d-flex align-items-center justify-content-center min-vh-100 bg-light">
      <div className="text-center bg-white p-4 rounded shadow-lg w-100" style={{ maxWidth: '500px' }}>
        <img src="/images/brand-logos/PAIU.png" alt="Logo" className="mb-3 mx-auto" style={{ width: '200px' }} />
        <h3 className="text-dark font-weight-bold fs-1 mb-3">
          Thank you <br /> for reaching out to us.
        </h3>
        <h6 className="text-danger mb-3 fs-2">Our counsellors will get in touch with you shortly,</h6>

        <div className="text-muted my-2 fs-2">
         
            <a href="tel:+971569456777" className="text-dark text-decoration-none mx-2">+971 56 945 6777</a>
       
        </div>

        <div className="text-muted mt-2 fs-2">
          <a href="https://www.paiu.ae/" className="text-dark text-decoration-none">
            www.paiu.ae
          </a>
        </div>

        <p className="text-muted my-2 fs-2">Stay connected with us on our social channels</p>

        <div className="d-flex justify-content-center mb-4">
          <ul className="list-inline">
            <li className="list-inline-item">
              <a href="https://www.facebook.com/paiuuae/" className="text-dark">
                <Facebook fontSize="large" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://x.com/paiuuae" className="text-dark">
                <XIcon fontSize="large" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://www.instagram.com/paiuuae/" className="text-dark">
                <Instagram fontSize="large" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://www.linkedin.com/company/paiu-paris-american-international-university-uae" className="text-dark">
                <LinkedIn fontSize="large" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://www.youtube.com/@paiuuae" className="text-dark">
                <YouTube fontSize="large" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
