import React from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Box,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  CoursesIcon,
  isMediumScreens,
  NationalityIcon,
  StudentIcon,
} from "../../Constants/Constants";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-scroll";

const data = [
  {
    title: "Globally Recognised & Accredited",
    description:
      "Our programs are nationally and internationally accredited,ensuring your degree is valued and recognized worldwide.",
  },
  {
    title: "Exceptional Affordability",
    description:
      "Receive high-quality education at a reasonable cost, with the added benefit of convenient, tailored monthly payment plans.",
  },
  {
    title: "Flexible Learning Options",
    description:
      "Access your coursework from anywhere with our comprehensive digital libraries and live online classes.",
  },
  {
    title: "Accelerated Degree Paths",
    description:
      " Opt for accelerated learning tracks to complete your MBA and master degrees faster, at your own pace.",
  },
  {
    title: "Experienced Faculty",
    description:
      "Learn from industry experts who bring deep professional knowledge and practical insights into the classroom.",
  },
  {
    title: "Multiple Enrollment Dates",
    description:
      "Unlike traditional schedules, students have the flexibility to start their academic journey multiple times throughout the year.",
  },
  {
    title: "Career-Oriented Programs",
    description:
      "Our courses are specifically designed to cater to the needs of working professionals, aligning with industry demands.",
  },
  {
    title: "Seamless Campus Transfers",
    description:
      " During your studies, you have the option to transfer between our multiple campuses on a quarterly basis, providing flexibility and convenience.",
  },
];

const CustomCard = styled(Card)({
  backgroundColor: "transparent",
  border: "1px solid #1a0060",
  transition: "0.3s",
  "&:hover": {
    backgroundColor: "#1a0060",
    color: "#fff",
  },
  "&:hover h6, &:hover p": {
    color: "#fff !important",
  },
  "&:hover .MuiTypography-root": {
    color: "#fff",
  },
  "&:hover .MuiSvgIcon-root": {
    color: "#fff",
  },
  borderRadius: "8px",
  marginBottom: "16px",
});

const CustomStack = styled(Stack)({
  color: "#000",
  transition: "0.3s",
  "&:hover": {
    color: "#fff",
  },
  "&:hover .MuiTypography-root": {
    color: "#fff",
  },
  "&:hover .MuiSvgIcon-root": {
    color: "#fff",
  },
});

const HighlightSection = styled(Box)({
  backgroundColor: "#f8e9ef",
  padding: "48px 0",
  borderRadius: "20px",
});

const CardsSection = styled(Grid)({
  paddingTop: "48px",
  textAlign: "center",
});

const Highlights = () => {
  const isMediumScreen = useMediaQuery(isMediumScreens);
  return (
    <Container maxWidth="lg" sx={{ pt: isMediumScreen ? "0px" : "130px" }}>
      <ScrollAnimation
        animateIn="fadeInUp"
        animateOut="fadeInOut"
        animateOnce={true}
      >
        <HighlightSection>
          <Grid
            container
            spacing={2}
            justifyContent="space-evenly"
            alignItems={"center"}
            p={2}
          >
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <Stack>
                  <CoursesIcon />
                </Stack>
                <Stack direction={"column"}>
                  <h6
                    style={{
                      color: "#1a0060",
                      marginBottom: "0px",
                      fontSize: isMediumScreen ? "26px" : "46px",
                    }}
                  >
                    100+
                  </h6>
                  <p
                    style={{
                      color: "#1a0060",
                      margin: "0px",
                      fontSize: isMediumScreen ? "26px" : "36px",
                    }}
                  >
                    Courses
                  </p>
                </Stack>
              </Stack>
            </Grid>{" "}
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <Stack>
                  <StudentIcon />
                </Stack>
                <Stack direction={"column"}>
                  <h6
                    style={{
                      color: "#1a0060",
                      marginBottom: "0px",
                      fontSize: isMediumScreen ? "26px" : "46px",
                    }}
                  >
                    4000+
                  </h6>
                  <p
                    style={{
                      color: "#1a0060",
                      margin: "0px",
                      fontSize: isMediumScreen ? "26px" : "36px",
                    }}
                  >
                    Students
                  </p>
                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <Stack>
                  <NationalityIcon />
                </Stack>
                <Stack direction={"column"}>
                  <h6
                    style={{
                      color: "#1a0060",
                      marginBottom: "0px",
                      fontSize: isMediumScreen ? "26px" : "46px",
                    }}
                  >
                    32+
                  </h6>
                  <p
                    style={{
                      color: "#1a0060",
                      margin: "0px",
                      fontSize: isMediumScreen ? "26px" : "36px",
                    }}
                  >
                    Nationalities
                  </p>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <CardsSection
            container
            spacing={2}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            p={2}
          >
            {data.map((item, index) => (
              <Grid item xs={12} sm={6} md={5} key={index}>
                <CustomCard>
                  <CardContent
                    sx={{ height: isMediumScreen ? "220px" : "150px" }}
                  >
                    <CustomStack
                      direction={"row"}
                      alignItems={"center"}
                      spacing={1}
                    >
                      <TaskAltIcon
                        sx={{ height: "42px", width: "42px", color: "#1a0060" }}
                      />

                      <h6 style={{ color: "#1a0060", marginBottom: "0px" }}>
                        {item.title}
                      </h6>
                    </CustomStack>
                    <Stack textAlign={"left"} pl={6}>
                      {" "}
                      <p style={{ color: "#1a0060", margin: "0px" }}>
                        {item.description}
                      </p>
                    </Stack>
                  </CardContent>
                </CustomCard>
              </Grid>
            ))}
          </CardsSection>
          {/* <Box pt={2} textAlign={"center"}>
            <div className="read-more-btn">
              <Link
                className="edu-btn"
                to="contact-us"
                spy={true}
                smooth={true}
                duration={200}
              >
                Claim Your Free Consultation Now
              </Link>
            </div>
          </Box> */}
          <Box textAlign={"center"} pt={3}>
<Link

                    to="contact-us"
                    spy={true}
                    smooth={true}
                    duration={200}
                  >
                    <button class="btnn">
                      <i class="animation"></i>Check your eligibility<i class="animation"></i>
                    </button>
                  </Link>
</Box>
        </HighlightSection>
      </ScrollAnimation>
    </Container>
  );
};

export default Highlights;
