import React from "react";
import { Element } from "react-scroll";
import HubspotContactForm from "../../components/form/HubspotContactForm";
import CustomForm from "../../components/form/CustomForm";
import ContactMeForm from "../../components/contact/ContactMeForm";

const ContactUs = () => {
  return (
    <>
      <Element name="contact-us">
        <div className="eduvibe-contact-us edu-contact-us-area edu-section-gap bg-color-white">
          <div className="container eduvibe-animated-shape">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="pre-title">Need Help?</span>
                  <h3 className="title">Get In Touch With us</h3>
                </div>
              </div>
            </div>
            <div className="row g-5 mt--20">
              <div className="col-lg-6">
                <div className="contact-info pr--70 pr_lg--0 pr_md--0 pr_sm--0">
                  <div className="row g-5">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="contact-address-card-1 website">
                        <div className="inner">
                          <div className="icon">
                            <i className="ri-global-line"></i>
                          </div>
                          <div className="content">
                            <h6 className="title">Our Website</h6>
                            <p>
                              <a href="https://paiu.ae/">www.paiu.ae</a>
                            </p>
                            {/* <p><a href="https://themeforest.net/user/devsvibe">www.wpvive.com</a></p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="contact-address-card-1 email">
                        <div className="inner">
                          <div className="icon">
                            <i className="icon-mail-open-line"></i>
                          </div>
                          <div className="content">
                            <h6 className="title">Email Us</h6>
                            <p>
                              <a href="mailto:admissions@paiu.ae">
                                admissions@paiu.ae
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="contact-address-card-1 phone">
                        <div className="inner">
                          <div className="icon">
                            <i className="icon-Headphone"></i>
                          </div>
                          <div className="content">
                            <h6 className="title">Call Us On</h6>
                            <p>
                              <a href="tel: 056 945 6777">056 945 6777</a>
                            </p>
                            <p>
                              <a href="tel: 054 223 6999">054 223 6999</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="contact-address-card-1 location">
                        <div className="inner">
                          <div className="icon">
                            <i className="icon-map-pin-line"></i>
                          </div>
                          <div className="content">
                            <h6 className="title">Our Location</h6>
                            <p style={{ fontSize: "13px" }}>
                              Amberjem Tower - 42nd Floor - Sheikh Khalifa Bin
                              Zayed St - Al Rashidiya 3 - Ajman
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                {/* <ContactUsForm formStyle="rnt-contact-form rwt-dynamic-form row" /> */}
                {/* <HubspotContactForm
                  region="na1"
                  portalId="143713869"
                  formId="daeb484d-2e8c-463e-9950-5e6f8ecfac20"
                /> */}
                
                <CustomForm />
                {/* <ContactMeForm /> */}
              </div>
            </div>

            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
              <div className="shape-image shape-image-1">
                <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
              </div>
              <div className="shape-image shape-image-2">
                <img src="/images/shapes/shape-02-08.png" alt="Shape Thumb" />
              </div>
              {/* <div className="shape-image shape-image-3">
                <img src="/images/shapes/shape-15.png" alt="Shape Thumb" />
              </div> */}
            </div>
          </div>
        </div>
      </Element>
    </>
  );
};
export default ContactUs;
