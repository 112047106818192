import { Grid, Stack, useMediaQuery } from "@mui/material";
import React from "react";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import { isMediumScreens, isSmallScreens } from "../../Constants/Constants";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-scroll";

function Credentials() {
  const isSmallScreen = useMediaQuery(isSmallScreens);
  const isMediumScreen = useMediaQuery(isMediumScreens);
  return (
    <>
      <div
        className="edu-purchase-guide-area edu-purchase-guide bg-color-white"
        style={{ paddingBottom: "50px" }}
      >
        <div className="container">
          <Grid
            container
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} pb={3}>
              {" "}
              <SectionTitle
                classes="text-center"
                slogan="Accreditations"
                title="Gain Global Recognition"
              />
            </Grid>
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} textAlign={"center"}>
                <p>
                  The International masters degree program prepares
                  professionals like you to experience the ever-evolving world's
                  workspace , first-hand. When you graduate with a minimum 60%
                  weighted average mark overall, you will receive a Master
                  Degree from The Paris American International University and a
                  Professional Certificate from Paris American International
                  University UAE.
                </p>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Stack
                  direction={isSmallScreen ? "column" : "row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  spacing={2}
                >
                  <img src="/images/brand-logos/ac-paris.webp"></img>
                  <img src="/images/brand-logos/qualiopi.webp"></img>
                  <img src="/images/brand-logos/france-acc.png" style={{height:"134px",width:"230px"}}></img>
                </Stack>
                <Stack textAlign={"center"} pt={5}>

<Link
                    // className="edu-btn"
                    to="contact-us"
                    spy={true}
                    smooth={true}
                    duration={200}
                  >
                    <button class="btnn">
                      <i class="animation"></i>Download Brochure<i class="animation"></i>
                    </button>
                  </Link>

                </Stack>
              </Grid>
            </ScrollAnimation>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default Credentials;
